import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../../styles/variables";
import { Background } from "react-imgix";
import { imgix } from "../../../../data/data";
import YouTubeEmbed from "../../../YouTubeEmbed";

interface FWGridProps {
  image: {
    url: string;
    title: string;
  };
  video?: string;
}

const FWGridDiv = styled.div`
  height: 25vh;
  width: 100vw;
  overflow: hidden;
  max-width: 1360px;
  margin: 0 auto;

  .extraSettings {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    height: 55vh;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: 100vh;
  }
  > div {
    width: 100%;
    height: 100%;
    background-position: center;
  }
`;

const FWGrid: React.FC<FWGridProps> = ({
  image,
  video,
  setLightboxIsOpen,
  setLightboxContent,
  lightboxInhalt,
}) => {
  const openLightbox = () => {
    setLightboxContent(lightboxInhalt);
    setLightboxIsOpen(true);
  };
  return (
    <FWGridDiv>
      {image ? (
        <div
          onClick={() => openLightbox()}
          className="hoverPointer extraSettings"
        >
          <Background
            src={image.url}
            imgixParams={imgix.fullImg}
            htmlAttributes={{ title: image.title }}
          />
        </div>
      ) : video ? (
        <div onClick={() => openLightbox()} className="hoverPointer">
          <YouTubeEmbed url={video} prev />
        </div>
      ) : (
        ""
      )}
    </FWGridDiv>
  );
};

export default FWGrid;
